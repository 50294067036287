import React, { useState } from 'react';
import '../../css/Menu.css';
import { Link } from 'react-scroll';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="menu">
      <div className="logo logo1"></div>
      <div className="logo logo2"></div>
      <button className="menu-button" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
      </button>
      <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
        <Link to="Windows" smooth={true}><li>Windows</li></Link>
        <Link to="Doors" smooth={true}><li>Doors</li></Link>
        <Link to="Quote" smooth={true}><li>Request a Quote</li></Link>
      </ul>
      
    </nav>
  );
}

export default Menu;