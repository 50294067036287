import React from 'react';
import '../css/Footer.css';
import logo from '../images/logo-white.svg'; // replace this with the path to your logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import ScrollToTop from './ScrollToTop';

const Footer = () => (
  <footer className="footer">
    <img src={logo} alt="Logo" className="logo" />
    <p className="copyright has-3-color">©2024 BNT WINDOWS & DOORS</p>
    <div className="social-media-icons">
        <a href="https://www.facebook.com/BNTIMPACTWINDOWS/" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://twitter.com/bntservices" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://www.instagram.com/bntservices/" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
        </a>
    </div>
    <ScrollToTop />
  </footer>
);

export default Footer;