import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import './impactdoors.css'; 
import CookieConsent from "react-cookie-consent";

const impactDoorsData = [
  {
    title: "Impact Resistance",
    description: "Hurricane Protection: PGT doors, especially those in the WinGuard® line, are engineered to withstand high winds and impact from flying debris during hurricanes. This makes them ideal for homes in coastal or storm-prone areas.",
    details: "Enhanced Security: The impact-resistant glass and robust construction provide an added layer of security, making it difficult for intruders to break in.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Energy Efficiency",
    description: "Insulation: PGT doors are designed to reduce heat transfer, helping to maintain a consistent indoor temperature. This can lead to lower energy bills by reducing the need for heating and cooling.",
    details: "Low-E Glass: Many PGT doors come with Low-E (low emissivity) glass, which reflects heat while allowing natural light to enter. This enhances energy efficiency without compromising on brightness.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Noise Reduction",
    description: "Sound Insulation: PGT doors are effective at reducing outdoor noise, creating a quieter and more comfortable indoor environment. This is especially beneficial for homes located near busy streets or noisy areas.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Customization and Design Options",
    description: "Variety of Styles: PGT offers a wide range of door styles, including French doors, sliding glass doors, and custom-designed doors. This allows homeowners to choose designs that match their architectural style and personal preferences.",
    details: "Customization: Homeowners can customize PGT doors with different finishes, hardware, and glass options, ensuring that the doors complement the overall design of the home.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Durability and Low Maintenance",
    description: "Long-Lasting Materials: PGT doors are made from high-quality materials that are resistant to corrosion, warping, and fading. This ensures that the doors maintain their appearance and functionality over time.",
    details: "Low Maintenance: PGT doors require minimal upkeep, making them a convenient choice for busy homeowners. The durable materials used in their construction resist wear and tear, reducing the need for frequent repairs or replacements.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Increased Home Value",
    description: "Curb Appeal: Installing PGT doors can enhance the aesthetic appeal of your home, contributing to higher resale value. The combination of durability, energy efficiency, and impact resistance makes PGT doors an attractive feature for potential buyers.",
    details: "Compliance with Building Codes: In hurricane-prone regions, having PGT doors that meet local building codes can be a significant selling point, as it assures buyers of the home's safety and compliance.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Environmental Benefits",
    description: "Sustainable Materials: PGT is committed to sustainability, using materials and manufacturing processes that minimize environmental impact. Choosing PGT doors can contribute to a more eco-friendly home.",
    details: "Energy Savings: The energy efficiency of PGT doors not only reduces utility costs but also lowers the home's carbon footprint by reducing the energy needed for heating and cooling.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Warranty and Support",
    description: "Comprehensive Warranty: PGT offers strong warranties on their doors, providing peace of mind and protection for your investment. The warranty coverage typically includes the door structure, glass, and hardware.",
    details: "Customer Support: PGT is known for its excellent customer service, offering support through authorized dealers and service centers.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Safety Features",
    description: "Tempered Glass: Many PGT doors come with tempered glass, which is designed to break into small, blunt pieces rather than sharp shards, reducing the risk of injury.",
    details: "Multipoint Locking Systems: Some PGT doors feature advanced locking systems that provide additional security compared to traditional single-point locks.",
    image: require('./images/Single_Hung_Window.jpg')
  }
];

const doorTypesData = [
  {
    title: "Sliding Glass Doors",
    description: "Features large glass panels that slide horizontally within a track, offering a smooth and space-saving operation.",
    benefits: "Maximizes natural light, provides wide views, and offers easy access to outdoor spaces.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "French Doors",
    description: "A pair of doors that swing open from the center, typically featuring glass panels for a classic, elegant look.",
    benefits: "Enhances indoor-outdoor flow, provides a traditional aesthetic, and allows for ample natural light.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Cabana Doors",
    description: "Smaller, single-hinged doors often used for pool houses, patios, or as exterior bathroom doors.",
    benefits: "Compact design, easy access to outdoor spaces, and customizable with glass options for added light.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Impact-Resistant Doors (WinGuard®)",
    description: "Doors specifically designed to withstand high winds and impacts, particularly in hurricane-prone areas.",
    benefits: "Provides superior protection against storms, enhances security, and meets stringent building codes.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Vinyl Sliding Glass Doors",
    description: "Sliding doors made from durable vinyl material, offering enhanced energy efficiency and low maintenance.",
    benefits: "Energy-efficient, resistant to corrosion and fading, and ideal for coastal environments.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Entry Doors",
    description: "The main front door of a home, available in various designs, including solid panels or with glass inserts.",
    benefits: "Boosts curb appeal, provides security, and can be customized with different finishes and hardware.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Bi-Fold Doors",
    description: "Doors that fold in sections, allowing for a wide opening that seamlessly connects indoor and outdoor spaces.",
    benefits: "Expands living space, provides a modern aesthetic, and offers flexibility in how the space is used.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Pivot Doors",
    description: "A door that rotates on a pivot hinge rather than traditional side hinges, creating a unique and modern entrance.",
    benefits: "Stylish and contemporary, allows for larger door sizes, and provides a focal point for the entryway.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Custom Doors",
    description: "Doors that can be tailored to specific designs, sizes, and materials to meet unique architectural needs.",
    benefits: "Fully customizable to match any design style, available in various materials, and can be designed to complement other PGT products.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Multi-Slide Doors",
    description: "Similar to sliding glass doors but with multiple panels that can slide and stack or disappear into a wall pocket.",
    benefits: "Creates expansive openings, ideal for large patios or outdoor areas, and enhances the indoor-outdoor connection.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Fiberglass Doors",
    description: "Doors made from fiberglass, which mimic the look of wood but with superior durability and low maintenance.",
    benefits: "Resists warping, cracking, and splitting, energy-efficient, and available in a range of finishes.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Aluminum Doors",
    description: "Doors constructed from lightweight but strong aluminum, often used for modern or contemporary designs.",
    benefits: "Durable, low-maintenance, and offers a sleek, modern appearance.",
    image: require('./images/Single_Hung_Window.jpg')
  }
];

const ImpactDoors = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      {/* Hero Section Carousel */}
      <Carousel className="carousel-windows" autoPlay interval={3000} infiniteLoop showThumbs={false}>
        {impactDoorsData.map((rating, index) => (
          <div key={index}>
            <img src={rating.image} alt={rating.title} />
            <div className="carousel-caption">
              <h5>{rating.title}</h5>
              <p>{rating.description}</p>
              <p>{rating.details}</p>
            </div>
          </div>
        ))}
      </Carousel>

      {/* Tabs Section */}
      <div className="container-windows tabs-section-windows mt-5">
        <h2>Types of PGT Doors</h2>
        <p>PGT Industries offers a variety of door types designed to meet different needs for security, energy efficiency, and aesthetic appeal. Here’s a list of the main types of PGT doors:</p>
        <ul className="nav nav-tabs nav-tabs-windows" id="myTab" role="tablist">
          {doorTypesData.map((window, index) => (
            <li className="nav-item" key={index}>
              <a
                className={`nav-link ${index === activeTab ? 'active' : ''}`}
                id={`tab-${index}`}
                data-toggle="tab"
                href={`#content-${index}`}
                role="tab"
                aria-controls={`content-${index}`}
                aria-selected={index === activeTab ? 'true' : 'false'}
                onClick={() => handleTabClick(index)}
              >
                {window.title}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          {doorTypesData.map((window, index) => (
            <div
              className={`tab-pane fade ${index === activeTab ? 'show active' : ''}`}
              id={`content-${index}`}
              role="tabpanel"
              aria-labelledby={`tab-${index}`}
              key={index}
            >
              <div className="tab-content-wrapper tab-content-wrapper-windows">
                <h3>{window.title}</h3>
                <img src={window.image} alt={window.title} className="tab-image" />
                <p><strong>Description:</strong> {window.description}</p>
                <p><strong>Benefits:</strong> {window.benefits}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

     
    </div>
  );
}
  
export default ImpactDoors