import React from 'react';
import './shopnow.css';
import CookieConsent from "react-cookie-consent";

const ImpactWindows = () => {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <div className="wrapper-new">
        menu
        hero 1
        hero 2
      </div>
      carousel windows
      <div>
        hero 3
      </div>
      carousel doors
      <div>
        hero 4
      </div>
      footer
    </div>

  );
}
  
export default ImpactWindows