import React, { useState } from 'react';
import '../../css/SliderDoors.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Slider = () => {
  const [current, setCurrent] = useState(0);
  const images = [...Array(8)].map((_, i) => `../../images/pgt-doors/PGT-DOORS-${i + 1}.png`);

  const handleLeftClick = () => {
    if (window.innerWidth < 768) {
      setCurrent(current => current === 0 ? images.length - 4 : current - 4);
    } else {
      setCurrent(current => current === 0 ? images.length - 4 : current - 4);
    }
  };
  
  const handleRightClick = () => {
    if (window.innerWidth < 768) {
      setCurrent(current => current === images.length - 4 ? 0 : current + 4);
    } else {
      setCurrent(current => current === images.length - 4 ? 0 : current + 4);
    }
  };

  return (
    <div className="slider-doors">
      <button type="button" className="arrow left" onClick={handleLeftClick}><FontAwesomeIcon icon={faChevronLeft} /></button>
      <div className="images-doors">
        {images.slice(current, current + 4).map((image, i) => <img key={i} src={image} alt={`PGT Windows ${current + i + 1}`} />)}
      </div>
      <button type="button" className="arrow right" onClick={handleRightClick}><FontAwesomeIcon icon={faChevronRight} /></button>
    </div>
  );
}

export default Slider;