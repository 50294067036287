import React, { useEffect } from 'react';
import '../css/Wrapper.css';
import Menu from './Menu/MenuBar';
import Hero1 from './Heros/Hero1';
import Hero2 from './Heros/Hero2';
import Hero3 from './Heros/Hero3';  
import Hero4 from './Heros/Hero4';
import CarouselWindows from './Carousels/CarouselWindows';
import CarouselDoors from './Carousels/CarouselDoors';
import Footer from './Footer';
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";


const NewHomePage = () => {
  useEffect(() => {
    ReactGA.initialize('G-1HD8LMCB9E');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <div className="wrapper wrapper-1">
        <Menu />
        <Hero1 />
        <Hero2 />
      </div>
      <CarouselWindows />
      <div>
        <Hero3 />
      </div>
      <CarouselDoors />
      <div>
        <Hero4 />
      </div>
      <Footer />
    </div>

  );
}

export default NewHomePage;