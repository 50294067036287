import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import '../../css/LeadsManagement.css';

function LeadsManagement() {
    const [email, setEmail] = useState('dave-ai@bntimpact.com');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);

    const [username, setUsername] = useState('');

    const [generatedOtp, setGeneratedOtp] = useState(null);
    const [resendOtp, setResendOtp] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();


    const handleSubmitEmail = (e) => {
        e.preventDefault();
        // if (!username.trim()) {
        //     alert('Please enter your username');
        //     return;
        // }
        // TODO: Submit the email and get the OTP in email
        // You can use an API call or any other method to achieve this
        // Generate a 6-digit OTP
        const otp = Math.floor(100000 + Math.random() * 900000);
        setGeneratedOtp(otp);
        setEmail(`${username}@bntimpact.com`);
        // Send an email using EmailJS
        //const email = `${username}@bntimpact.com`;
        emailjs.send('service_3g0zkmv', 'template_0arojng', { to_email: email, otp: otp }, 'ppw3kvH4y17H7oyNP')
        .then((result) => {
            console.log(result.text);
            setIsOtpSent(true);
            setResendOtp(false);
            setIsSubmitting(true);
        }, (error) => {
            console.log(error.text);
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    };

    const handleVerifyOtp = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        // Convert both otp and generatedOtp to numbers before comparing
        if (Number(otp) === generatedOtp) {
            sessionStorage.setItem('isAuthenticated', true);
            // Redirect to the dashboard
            navigate('/leads-management/dashboard');
            setIsSubmitting(false);
        } else {
            // Show an error message
            console.log('Incorrect OTP');
            // Enable the Resend OTP button
            setResendOtp(true);
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center">
            {!isOtpSent ? (
                <form onSubmit={handleSubmitEmail} className="form-group text-center mt-3">
                    <input
                        type="text"
                        placeholder="Enter your email"
                        value={username}
                        className="styled-input"
                        onChange={(e) => {
                            setUsername(e.target.value);
                            const value = e.target.value;
                            const regex = /^[a-z0-9-]+$/i;
                            if (regex.test(value)) {
                              setEmail(value);
                            }
                          }}
                    ></input>
                    <input
                        type="text"
                        value="@bntimpact.com"
                        readOnly
                        className="styled-input"
                    />
                    <button type="submit"className="btn btn-primary">{isSubmitting ? 'Processing...' : 'Submit'}</button>
                </form>
            ) : (
                <form onSubmit={handleVerifyOtp} className="form-group text-center mt-3">
                    <div>
                        <label>Please check your email for the One Time Password(OTP)</label>
                    </div>
                    <div>
                    <input
                        type="text"
                        placeholder="Enter the OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="styled-input"
                    />
                    <button type="submit" className="btn btn-primary">{isSubmitting ? 'Processing...' : 'Verify OTP'}</button>
                    </div>
                    
                </form>
            )}
            {resendOtp && (
            <div className="text-center mt-3">
                <button onClick={handleSubmitEmail} className="btn btn-secondary">Resend OTP</button>
            </div>
            )}
        </div>
    );
}

export default LeadsManagement;