import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
  
const HeroSection = () => {  
  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1>Let us help you create a space that truly reflects your unique style and personality</h1>
        <p>Discover amazing things here!</p>
        <Link to="/request-a-quote"style={{ textDecoration: 'none' }}>
          <button style={{ backgroundColor: 'red', color: '#fff', border: 'none', padding: '10px 20px', borderRadius: '5px' }}>Get Hurricane Protection</button>
        </Link>
        <p>OR</p>
        <p>Let's see other options</p>
        <div class="button-container">
          <a href="/" class="small-link-button">Home</a>
          <a href="/windows" class="small-link-button">Windows</a>
          <a href="/doors" class="small-link-button">Doors</a>
          <a href="/request-a-quote" class="small-link-button">Request a Quote</a>
          <a href="/home-inspection" class="small-link-button">Home Inspection</a>
          {/* <a href="/features" class="small-link-button">Features</a> */}
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
