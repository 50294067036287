import React from 'react';
import '../../css/Hero1.css';
import ButtonsHero1 from './ButtonsHero1';

const Hero1 = () => {
  return (
    <div className="hero1">
      <div className='buttonshero1'>
        <ButtonsHero1 />  
      </div>
    </div>
  );
}

export default Hero1;