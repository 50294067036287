import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import logo from '../logo.svg';
import '../App.css';
import Menu from '../components/Responsivemenu'
import { Link } from 'react-router-dom';
import Workflowdesign from "./Workflowdesign";

const App = () => {
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    let id = Cookies.get("userID");
    let numberOfVisits = Number(Cookies.get('visits') || '0');

    if (id == null){
      id = uuidv4();
      Cookies.set("userID", id);
      numberOfVisits = 1;
    } else {
      numberOfVisits += 1;  
    }

    localStorage.setItem('visits', numberOfVisits.toString());
    console.log("You've visited this site " + numberOfVisits + " time(s).");
    
    setUserID(id);
  }, []);

  return (
    <div className="App">
      <Menu/>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        Animated SVG Logo of BNT ↑
        <p>
        PGT Impact Resistant Windows and Doors. Authorized Contractor BNT. 
        </p>
        <p>
        New Home Page Design ↓ 
        </p>
        <Link to="/home-new" className="small-link-button" rel="noopener noreferrer">
          New Home Page
        </Link>
        <Workflowdesign />
        <div className='visitCount'>
          Welcome to this site! <br/>
          Your User ID is: {userID}
        </div>
      </header>
      
    </div>
  );
}

export default App;
