import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import { Carousel } from 'react-responsive-carousel'; 
import CookieConsent from "react-cookie-consent";
import './impactwindows.css'; 


import { Carousel as BootstrapCarousel } from 'bootstrap'; 

const windowsData = [
  {
    title: "Single Hung Windows",
    description: "Features a fixed upper sash and a movable lower sash that slides vertically.",
    benefits: "Space-saving design, easy to operate, and ideal for small spaces.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Double Hung Windows",
    description: "Both upper and lower sashes are movable, allowing for versatile ventilation.",
    benefits: "Easy to clean, great for ventilation, and traditional aesthetic appeal.",
    image: require('./images/Double_Hung_Window.webp')
  },
  {
    title: "Horizontal Roller (Sliding) Windows",
    description: "Sashes slide horizontally within the frame.",
    benefits: "Smooth operation, ideal for wide spaces, and easy to open and close.",
    image: require('./images/Horizontal-Roller-Sliding-Windows.webp')
  },
  {
    title: "Casement Windows",
    description: "Hinged at the side, these windows open outward like a door.",
    benefits: "Excellent for ventilation, energy-efficient seal, and modern look.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Awning Windows",
    description: "Hinged at the top, these windows open outward from the bottom.",
    benefits: "Allows ventilation even during rain, energy-efficient, and modern style.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Picture Windows",
    description: "Fixed, non-operable windows that provide an unobstructed view.",
    benefits: "Maximizes natural light, energy-efficient, and ideal for scenic views.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Architectural Windows",
    description: "Custom-shaped windows that include geometric shapes, arches, and more.",
    benefits: "Adds unique design elements, complements other window types, and customizable.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Bay Windows",
    description: "A combination of three or more windows that extend outward from the home.",
    benefits: "Creates more space inside, enhances curb appeal, and offers panoramic views.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Bow Windows",
    description: "Similar to bay windows, but typically with four or more windows creating a rounded appearance.",
    benefits: "Adds elegance, increases natural light, and offers wide-angle views.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Fixed Lite Windows",
    description: "Non-operable windows designed to match other window types.",
    benefits: "Enhances design continuity, energy-efficient, and ideal for areas where ventilation is not needed.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Sliding Glass Door like Window",
    description: "Large glass panels that slide horizontally to open and close.",
    benefits: "Maximizes views, easy access to outdoor spaces, and great for large openings.",
    image: require('./images/Single_Hung_Window.jpg')
  }
];

const hurricaneRatingsData = [
  {
    title: "Pressure Ratings",
    description: "PGT windows are tested for both design pressure (DP) and impact resistance. The design pressure rating indicates how much wind force the window can withstand before failing.",
    details: "Miami-Dade County NOA: Many PGT windows are approved by the Miami-Dade County Notice of Acceptance (NOA), one of the strictest standards for impact-resistant windows. HVHZ Compliance: PGT windows are compliant with High-Velocity Hurricane Zone (HVHZ) standards, making them suitable for use in the most hurricane-prone areas. PGT windows are specifically engineered to provide protection during hurricanes by preventing flying debris from penetrating the home and by withstanding high wind pressures. This makes them a top choice for homeowners in hurricane-prone regions.",
    image: require('./images/Pressure-Rating-Hurricane.webp')
  },
  {
    title: "PGT WinGuard® Windows",
    description: "Hurricane Rating: PGT WinGuard® windows are impact-resistant and designed to withstand the pressures of Category 5 hurricanes. These windows can resist winds of up to 200 mph and meet or exceed the most stringent coastal building codes, including the Miami-Dade County and Florida Building Code standards.",
    details: "Glass Type: Laminated impact-resistant glass.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "PGT WinGuard® Vinyl Windows",
    description: "Hurricane Rating: These windows also meet or exceed building codes for impact resistance, designed for high-velocity hurricane zones (HVHZ). They provide protection against winds up to 200 mph.",
    details: "Glass Type: Laminated impact-resistant glass with vinyl frames for additional insulation and energy efficiency.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "PGT EnergyVue® Windows",
    description: "Hurricane Rating: While primarily designed for energy efficiency, PGT EnergyVue® windows can be upgraded with impact-resistant glass to meet hurricane protection standards similar to WinGuard® products. They are also rated for winds up to 200 mph.",
    details: "Glass Type: Available with impact-resistant glass.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "PGT Custom Windows",
    description: "Hurricane Rating: PGT offers custom solutions that can be tailored to meet specific hurricane impact resistance requirements, depending on the type of glass and frame materials selected.",
    details: "Glass Type: Various options, including laminated impact-resistant glass.",
    image: require('./images/Single_Hung_Window.jpg')
  },
  {
    title: "Why Impact Windows?",
    description: "PGT windows are known for their high performance and durability, especially in areas prone to hurricanes. The hurricane rating of PGT windows depends on the specific product line and the type of glass used.",
    details: "Here's an overview: Scroll Down",
    image: require('./images/Single_Hung_Window.jpg')
  }
];

const ImpactWindows = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      {/* Hero Section Carousel */}
      <Carousel className="carousel-windows" autoPlay interval={3000} infiniteLoop showThumbs={false}>
        {hurricaneRatingsData.map((rating, index) => (
          <div key={index}>
            <img src={rating.image} alt={rating.title} />
            <div className="carousel-caption">
              <h5>{rating.title}</h5>
              <p>{rating.description}</p>
              <p>{rating.details}</p>
            </div>
          </div>
        ))}
      </Carousel>

      {/* Tabs Section */}
      <div className="container-windows tabs-section-windows mt-5">
        <h2>Types of PGT Windows</h2>
        <p>PGT Industries offers a variety of window types, each designed to meet specific needs for safety, energy efficiency, and aesthetics. Here's a list of the main types of PGT windows:</p>
        <ul className="nav nav-tabs nav-tabs-windows" id="myTab" role="tablist">
          {windowsData.map((window, index) => (
            <li className="nav-item" key={index}>
              <a
                className={`nav-link ${index === activeTab ? 'active' : ''}`}
                id={`tab-${index}`}
                data-toggle="tab"
                href={`#content-${index}`}
                role="tab"
                aria-controls={`content-${index}`}
                aria-selected={index === activeTab ? 'true' : 'false'}
                onClick={() => handleTabClick(index)}
              >
                {window.title}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          {windowsData.map((window, index) => (
            <div
              className={`tab-pane fade ${index === activeTab ? 'show active' : ''}`}
              id={`content-${index}`}
              role="tabpanel"
              aria-labelledby={`tab-${index}`}
              key={index}
            >
              <div className="tab-content-wrapper tab-content-wrapper-windows">
                <h3>{window.title}</h3>
                <img src={window.image} alt={window.title} className="tab-image" />
                <p><strong>Description:</strong> {window.description}</p>
                <p><strong>Benefits:</strong> {window.benefits}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

     
    </div>
  );
}

export default ImpactWindows;