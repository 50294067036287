import React from 'react';
import '../../css/Wrapper.css';
import Menu from '../Menu/MenuBar';
import Hero1 from '../Heros/Hero1';
import Hero2 from '../Heros/Hero2';
import Hero3 from '../Heros/Hero3';  
import Hero4 from '../Heros/Hero4';
import CarouselWindows from '../Carousels/CarouselWindows';
import CarouselDoors from '../Carousels/CarouselDoors';
import Footer from '../Footer';

const RainyGreenHome = () => {
  return (
    <div>
      <div className="wrapper wrapper-18">
        <Menu />
        <Hero1 />
        <Hero2 />
      </div>
      <CarouselWindows />
      <div>
        <Hero3 />
      </div>
      <CarouselDoors />
      <div>
        <Hero4 />
      </div>
      <Footer />
    </div>

  );
}

export default RainyGreenHome;