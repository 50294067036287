import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

const RainEffect = ({ numDrops = 100, windStrength = 0, fallSpeed = 5 }) => {
  const canvasRef = useRef(null);
  const audioRef = useRef(); 
  const [isMuted, setIsMuted] = useState(true); // By default, the audio is off

  const toggleMute = useCallback(() => {
    const audio = audioRef.current;
    if (isMuted) {
      audio.play();
    } else {
      audio.pause();
    }
    setIsMuted(!isMuted);
  }, [isMuted]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    function Drop() {
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;
      this.speed = fallSpeed; // Speed of falling rain
      this.length = 5 + Math.random() * 10;
      this.windOffset = Math.random() * windStrength;

      this.update = function () {
        this.y += this.speed;
        this.x += this.windOffset;
        if (this.y > canvas.height) {
          this.y = 0;
          this.x = Math.random() * canvas.width;
        }
      };

      this.draw = function () {
        ctx.beginPath();
        ctx.moveTo(this.x, this.y);
        ctx.lineTo(this.x, this.y + this.length);
        ctx.strokeStyle = '#F0F0F0'; // Whitish-grey color
        ctx.lineWidth = 2;
        ctx.stroke();
      };
    }

    function createDrops() {
      const drops = [];
      for (let i = 0; i < numDrops; i++) {
        drops.push(new Drop());
      }
      return drops;
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const drops = createDrops();

      drops.forEach((drop) => {
        drop.update();
        drop.draw();
      });

      animationFrameId = requestAnimationFrame(animate);
    }

    animate();

    

  return () => {
    cancelAnimationFrame(animationFrameId);
    };
  }, [numDrops, windStrength, fallSpeed]); 

  return (
    <div className="sky" style={{ position: 'relative', backgroundColor: '#000', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
      <audio ref={audioRef} src="./tornado.mp3" loop /> {/* Add the audio element */}
      <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <button style={{ backgroundColor: 'red', color: '#fff', border: 'none', padding: '10px 20px', borderRadius: '5px' }}>Get Hurricane Protection</button>
        </Link>
      </div>
      <button onClick={toggleMute} style={{ position: 'fixed', bottom: '30px', right: '30px', backgroundColor: 'transparent', border: 'none' }}>
        <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} size="2x" color="#fff" />
      </button>
    </div>
  );
};

export default RainEffect;
