import React, { useState, useEffect } from 'react';
import data from './data.json'; // Replace './data.json' with the path to your JSON file
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Tabs, Tab } from 'react-bootstrap';
import '../../css/LeadsDashboard.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

function LeadsDashboard() {
    const [key, setKey] = useState('home');
    const [newLeads, setNewLeads] = useState([]);
    const [list1, setList1] = useState([]);
    const [list2, setList2] = useState([]);
    const [list3, setList3] = useState([]);

    const [fadingItem, setFadingItem] = useState(null);
    const navigate = useNavigate();

    const keysToDisplay = ['Full name', 'Email', 'Phone number', 'Zip/Postal code', 'Submit time EDT']; // Replace these with your actual field names

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('isAuthenticated');
        if (!isAuthenticated) {
            navigate('/leads-management');
        }
        // Load the data from the JSON file into the state
        setNewLeads(data);
    }, [navigate]); 

    const handlePushToHubspot = (item) => {
        setFadingItem(item);
        setTimeout(() => {
            setList1(prevList => [...prevList, item]);
            setNewLeads(prevLeads => prevLeads.filter(lead => lead !== item));
            setFadingItem(null);
        }, 50); // Replace 2000 with the duration of your fade-out animation in milliseconds
    };
    const handleDelete = (item) => {
        setFadingItem(item);
        setTimeout(() => {
            setList2(prevList => [...prevList, item]);
            setNewLeads(prevList => prevList.filter(lead => lead !== item));
            setFadingItem(null);
        }, 50); // Replace 2000 with the duration of your fade-out animation in milliseconds
    };

    const handleAuction = (item) => {
        setFadingItem(item);
        setTimeout(() => {
            setList3(prevList => [...prevList, item]);
            setNewLeads(prevList => prevList.filter(lead => lead !== item));
            setFadingItem(null);
        }, 50); // Replace 2000 with the duration of your fade-out animation in milliseconds
    };

    const handleRevert = (item, fromList, setFromList) => {
        setFromList(prevList => prevList.filter(lead => lead !== item));
        setNewLeads(prevList => [...prevList, item]);
    };

    const handleLogout = () => {
        // Remove the isAuthenticated item from the session storage
        sessionStorage.removeItem('isAuthenticated');
        // Redirect to the login page
        window.location.href = '/leads-management';
    };

    const handleCommit = () => {
        // TODO: Save your changes here
        console.log('Changes committed');
    };

    return (
        <div>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="home" title="New Leads">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Actions</th>
                                {keysToDisplay.map((key, index) => (
                                    <th key={index}>{key}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {newLeads.map((item, index) => (
                                <tr key={index} className={item === fadingItem ? 'fading' : ''}>
                                    <td>
                                        <Button variant="primary" onClick={() => handlePushToHubspot(item)} style={{ marginRight: '10px' }}>Push to Hubspot</Button>
                                        <Button variant="danger" onClick={() => handleDelete(item)} style={{ marginRight: '10px' }}>Delete</Button>
                                        <Button variant="info" onClick={() => handleAuction(item)}>Auction</Button>
                                    </td>
                                    {keysToDisplay.map((key, keyIndex) => (
                                        <td key={keyIndex} 
                                            onDoubleClick={(e) => { if (key !== 'Submit time EDT') e.target.contentEditable = true; }} 
                                            onBlur={(e) => {
                                                if (key !== 'Submit time EDT') {
                                                    e.target.contentEditable = false;
                                                    item[key] = e.target.innerText;
                                                }
                                            }}
                                        >
                                            {item[key]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="list1" title="Hubspot List">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {keysToDisplay.map((key, index) => (
                                    <th key={index}>{key}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {list1.map((item, index) => (
                            <tr key={index}>
                                {keysToDisplay.map((key, keyIndex) => (
                                    <td key={keyIndex}>{item[key]}</td>
                                ))}
                                <td>
                                    <Button variant="secondary" onClick={() => handleRevert(item, list1, setList1)}>Revert</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="list2" title="Delete List">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {keysToDisplay.map((key, index) => (
                                    <th key={index}>{key}</th>
                                ))}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list2.map((item, index) => (
                                <tr key={index}>
                                    {keysToDisplay.map((key, keyIndex) => (
                                        <td key={keyIndex}>{item[key]}</td>
                                    ))}
                                    <td>
                                        <Button variant="secondary" onClick={() => handleRevert(item, list2, setList2)}>Revert</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="list3" title="Auction List">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {keysToDisplay.map((key, index) => (
                                    <th key={index}>{key}</th>
                                ))}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list3.map((item, index) => (
                                <tr key={index}>
                                    {keysToDisplay.map((key, keyIndex) => (
                                        <td key={keyIndex}>{item[key]}</td>
                                    ))}
                                    <td>
                                        <Button variant="secondary" onClick={() => handleRevert(item, list3, setList3)}>Revert</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="commit" title="Commit Changes">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <p>Save progress and submit data to hubspot</p>
                        <Button variant="primary" onClick={handleCommit} style={{ marginBottom: '10px' }}>Save Progress and Sync</Button>
                        <Button variant="danger" onClick={handleLogout}>Logout</Button>
                        <p>Note: The fields are editable in New leads tab. If you want to edit the data in other tabs, use revert and edit.</p>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}

export default LeadsDashboard;