import React from 'react'
import '../App.css';
import Workflowdesign from './Workflowdesign';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from '../components/Responsivemenu'

const Windows = () => {
  return (
    <div className="App">
      <Menu/>
      <header className="App-header">
        Windows to Choose ↓ 
        <p> -- 
            1. Style -- 
            
            2. Performance --  
            
            3. Frame --  
            
            4. Product Line -- 
        </p>
        <p>
            How many Windows do you need? 
        </p>
        <p>
        <button className="btn btn-danger spaced-button">1</button>
        <button className="btn btn-danger spaced-button">2</button>
        <button className="btn btn-danger spaced-button">3</button>
        <button className="btn btn-danger spaced-button">4</button>
        <button className="btn btn-danger spaced-button">5+</button>
        <button className="btn btn-danger spaced-button">10+</button>
        <button className="btn btn-danger spaced-button">15+</button>

        </p>
        <Workflowdesign/>
      </header>
    </div>
  )
}

export default Windows