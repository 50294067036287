import React from 'react';
import '../../css/Hero3.css';
import doorsImage from '../../images/whydoors.png'; // replace with the path to your image
import { Link } from 'react-router-dom';

const Hero3 = () => {
  return (
    <div className="hero3">
      <div className="column">
        <h1>WHY IMPACT DOORS?</h1>
          <Link to="/impact-doors" className="learn-more-button">
            Learn More
          </Link>
        <div className="icon"><div className="arrow-2"></div></div>
      </div>
      <div className="column">
        <img src={doorsImage} alt="Window" />
      </div>
    </div>
  );
}

export default Hero3;