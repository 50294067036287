import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const ResponsiveMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="responsive-menu">
      <div className="menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </div>
      <div className={isMenuOpen ? "menu-items open" : "menu-items"}>
        <Link to="/home-new" onClick={toggleMenu}>New Home Page</Link>
        <Link to="/windows" onClick={toggleMenu}>Windows</Link>
        <Link to="/doors" onClick={toggleMenu}>Doors</Link>
        <Link to="/request-a-quote" onClick={toggleMenu}>Quote</Link>
        <Link to="/home-inspection" onClick={toggleMenu}>Inspection</Link>
      </div>
    </div>
  );
}

export default ResponsiveMenu;
