import React, { useState } from 'react';
import '../../css/QuoteForm.css';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';


const ContactForm = ({dbuttonvalue, wbuttonvalue}) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '+1',
    address: '',
    zipCode: '',
    source: 'https://pgt-southflorida.com'
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [id, setId] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
      if (name === 'phone') {
        if (!value.startsWith('+1')) {
          e.target.value = `+1${value.slice(2)}`;
        }
      }
    
      setFormData(prevState => ({
        ...prevState,
        [name]: e.target.value
      }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let id = Cookies.get('id');
    let wbuttonValue = Cookies.get('wbuttonValue');
    let dbuttonValue = Cookies.get('dbuttonValue');
    if (!id) {
      id = uuidv4();
      Cookies.set('id', id);
    }
    const formDatafinal = {
      id,
      wbuttonValue,
      dbuttonValue,
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      address: formData.address,
      zipCode: formData.zipCode,
    };
    setId(id);
    console.log(formDatafinal);
    console.log(id);
    // mongo start
    const response = await fetch('https://mongo.exinary.website/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        zipcode: formData.zipCode,
        address: formData.address,
        uuid: id,
        wbuttonvalue: wbuttonValue,
        dbuttonvalue: dbuttonValue,
        source: formData.source
      }),
    });

    if (response.ok) {
      setIsSubmitted(true);
      setId(await response.text());
    } else {
      console.error('Error:', response.status, response.statusText);
    }
    // mongo end
    setIsSubmitted(true);
    setIsSubmitting(false); 
  };

  if (isSubmitted) {
    return (
      <div className="container-fluid mt-5">
        <div className="alert alert-success" role="alert">
          Thank you for your contact information. We will get back to you soon. Please note your unique ID for future reference. {id}.
          <button type="button" className="btn btn-link" onClick={() => setIsSubmitted(false)}>Re-submit</button>
        </div>
        <div className="card text-center">
          <div className="card-body">
            <h5 className="card-title">New Promotion</h5>
            <p className="card-text">We have offers on solar energy and florida safe home grants. Check eligibility now!</p>
            <Link to="https://myfloridasafehomes.com/" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input type="text" className="form-control" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onInput={handleChange} pattern="^\+1\d{10}$" required />
            <div className="form-text">Please enter phone number in format: +1xxxxxxxxxx</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="zipCode" className="form-label">Zip Code</label>
            <input type="text" className="form-control" id="zipCode" name="zipCode" value={formData.zipCode} onChange={handleChange} pattern="[0-9]{5}" required />
            <div className="form-text">Please enter 5-digit zip code</div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="address" className="form-label">Address</label>
        <textarea className="form-control" id="address" name="address" value={formData.address} onChange={handleChange} rows="3" required></textarea>
      </div>
      <div className="d-flex justify-content-center">
        {isSubmitting ? (
          <p style={{ color: 'green', fontWeight: 'bold' }}>We are processing the information you provided...</p>
        ) : (
          <button type="submit" className="learn-more-button">Request a Free Quote</button>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
