import logo from '../logo.svg';
import '../App.css';
import Workflowdesign from './Workflowdesign';
import Quoteform from './Quoteform'
import Menu from '../components/Responsivemenu'

function Requestquote() {
  return (
    <div className="App">
      <Menu/>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        We can put interaction interests list here ↑
        <p>
        Request a quote FORM + attached-Interaction Details
        </p>
        <p>
          <Quoteform/>
        </p>
        <Workflowdesign/>
      </header>
    </div>
  );
}

export default Requestquote;
