import React from 'react';
import { BrowserRouter as Router, Route, Routes,  } from 'react-router-dom';

import Home from './components/Home'
import Windows from './components/Windows'
import Doors from './components/Doors'
import Requestquote from './components/Requestquote'
import Homeinspection from './components/Homeinspection';
import Hero from './components/Hero'
import Menu from './components/Responsivemenu'
import Rain from './components/Rain'
import NewHome from './components/NewHomepage'

import ImpactWindows from './components/ImpactWindows/ImpactWindows'
import ImpactDoors from './components/ImpactDoors/ImpactDoors'
import ShopNow from './components/ShopNow/ShopNow'

import AutumnGardenHome from './components/moods/AutumnGardenHome'
import AutumnNightHome from './components/moods/AutumnNightHome';
import CloudyNightHome from './components/moods/CloudyNightHome';
import DewAutumnHome from './components/moods/DewAutumnHome';
import DewGardenHome from './components/moods/DewGardenHome';
import EarlyMorningHome from './components/moods/EarlyMorningHome';
import EveningGardenHome from './components/moods/EveningGardenHome';
import EveningGreenHome from './components/moods/EveningGreenHome';
import GreenGardenHome from './components/moods/GreenGardenHome';
import EveningHome from './components/moods/EveningHome';
import HurricaneEveningHome from './components/moods/HurricaneEveningHome';
import NightGreenHome from './components/moods/NightGreenHome';
import NightSnowHome from './components/moods/NightSnowHome';
import NoonGardenHome from './components/moods/NoonGardenHome';
import RainyAutumnHome from './components/moods/RainyAutumnHome';
import SnowGardenHome from './components/moods/SnowGardenHome';
import RainyGreenHome from './components/moods/RainyGreenHome';
import LeadsManagement from './components/Leads/LeadsManagement';
import LeadsDashboard from './components/Leads/LeadsDashboard';
import CsvJson from './components/Leads/CsvJson';

function App() {

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/home" element={<Home/>}/>
          <Route path="/windows" element={<Windows/>}/>
          <Route path="/doors" element={<Doors/>}/>
          <Route path="/request-a-quote" element={<Requestquote/>}/>
          <Route path="/home-inspection" element={<Homeinspection/>}/>
          <Route path="/hero" element={<Hero/>}/>
          <Route path="/menu" element={<Menu/>}/>
          <Route path="/rain" element={<Rain numDrops={100} windStrength={4} fallSpeed={7} />}/>
          <Route path="/" element={<NewHome />}/>
          <Route path="/autumn-garden-home" element={<AutumnGardenHome />}/>
          <Route path="/autumn-night-home" element={<AutumnNightHome />}/>
          <Route path="/cloudy-night-home" element={<CloudyNightHome />}/>
          <Route path="/dew-autumn-home" element={<DewAutumnHome />}/>
          <Route path="/dew-garden-home" element={<DewGardenHome />}/>
          <Route path="/early-morning-home" element={<EarlyMorningHome />}/>
          <Route path="/evening-garden-home" element={<EveningGardenHome/>}/>
          <Route path="/evening-green-home" element={<EveningGreenHome />}/>
          <Route path="/green-garden-home" element={<GreenGardenHome />}/>
          <Route path="/evening-home" element={<EveningHome />}/>
          <Route path="/hurricane-evening-home" element={<HurricaneEveningHome />}/>
          <Route path="/night-green-home" element={<NightGreenHome />}/>
          <Route path="/night-snow-home" element={<NightSnowHome />}/>
          <Route path="/noon-garden-home" element={<NoonGardenHome />}/>
          <Route path="/rainy-autumn-home" element={<RainyAutumnHome />}/>
          <Route path="/snow-garden-home" element={<SnowGardenHome />}/>
          <Route path="/rainy-green-home" element={<RainyGreenHome />}/>
          <Route path="/leads-management" element={<LeadsManagement />}/>
          <Route path="/leads-management/dashboard" element={<LeadsDashboard />}/>
          <Route path="/leads-management/dashboard/csvjson" element={<CsvJson />}/>
          <Route element={<Home/>}/>
          
          <Route path="/impact-windows" element={<ImpactWindows/>}/>
          <Route path="/impact-doors" element={<ImpactDoors/>}/>
          <Route path="/shop-now" element={<ShopNow/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
