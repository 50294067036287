import React from 'react';
import { Link } from 'react-router-dom';

const ButtonsHero1 = () => {
  const urls = [
    '/',
    '/autumn-garden-home',
    '/autumn-night-home',
    '/cloudy-night-home',
    '/dew-autumn-home',
    '/dew-garden-home',
    '/early-morning-home',
    '/evening-garden-home',
    '/evening-green-home',
    '/evening-home',
    '/green-garden-home',
    '/hurricane-evening-home',
    '/night-green-home',
    '/night-snow-home',
    '/noon-garden-home',
    '/rainy-autumn-home',
    '/rainy-green-home'
  ];

  return (
    <div className="slider" title="Click on the dots to navigate">
      <div className="dots">
        {urls.map((url, index) => (
          <Link 
            key={index} 
            to={url} 
            className="dot"
          >
            •
          </Link>
        ))}
      </div>
      {/* <p className="navigation-instruction">Click on the dots to navigate</p> */}
    </div>
  );
};

export default ButtonsHero1;