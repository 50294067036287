import React from 'react'
import '../App.css';
import Workflowdesign from './Workflowdesign';
import Menu from '../components/Responsivemenu'

const Doors = () => {
    return (
        <div className="App">
          <Menu/>
          <header className="App-header">
            Doors to Choose ↓ 
            <p> -- 
            1. Style -- 
            
            2. Performance --  
            
            3. Frame --  
            
            4. Product Line -- 
            </p>
            <p>
            How many Doors do you need? 
            </p>
            <p>
              <button className="btn btn-success spaced-button">1</button>
              <button className="btn btn-success spaced-button">2</button>
              <button className="btn btn-success spaced-button">3</button>
              <button className="btn btn-success spaced-button">4</button>
              <button className="btn btn-success spaced-button">5+</button>
            </p>
            <Workflowdesign/>
          </header>
          {/* - Architectural
                - Architectural Picture
                - Awning
                - Casement
                - Casement Picture
                - Double Hung
                - Fixed Lite Architectural
                - Horizontal Roller
                - Picture
                - Single Hung */}
        </div>
      )
}

export default Doors