import React from 'react'
import logo from '../logo.svg';
import Menu from '../components/Responsivemenu'

import Workflowdesign from './Workflowdesign';
import Quoteform from './Quoteform'

import '../App.css';

const Homeinspection = () => {
    return (
        <div className="App">
          <Menu/>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            Schedule Home Inspection ↑
            <p>
            Request a Free Home Inspection + attached-Interaction Details
            </p>
            <p>
              <Quoteform/>
            </p>
            <Workflowdesign/>
          </header>
        </div>
      );
}

export default Homeinspection