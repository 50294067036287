import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/Carousel.css';
import '../../css/buttonswandd.css'
import Slider from './SliderWindows';
import Cookies from 'js-cookie';

const Carousel = () => {
  const buttons = ['1', '2', '3', '4', '5+', '10+', '15+'];
  const [wbuttonValue, setButtonValue] = useState('');

  useEffect(() => {
    const cookieValue = Cookies.get('wbuttonValue');
    if (cookieValue) {
      setButtonValue(cookieValue);
    }
  }, []);

  const handleClick = (value) => {
    setButtonValue(value);
    Cookies.set('wbuttonValue', value);
  };

  return (
    <div id="Windows" className="carousel">
      {wbuttonValue ? (
        <div className="content">
          <h1>Total <span>Windows : {wbuttonValue} </span><Link to="#" className="btn btn-primary change-number" onClick={() => setButtonValue('')}>
            Change Selection
          </Link></h1>
          
        </div>
      ) : (
        <>
          <div className="content">
            <h1>How many <span>WINDOWS</span> do you need?</h1>
            <div className="buttons">
              {buttons.map((text, i) => <button type="button" key={i} className={`button-${i + 1}`} onClick={() => handleClick(text)}>{text}</button>)}
            </div>
          </div>
        </>
      )}
      <Slider />
      <div className='sales-text'>
        Discover the ultimate in home protection with PGT impact-resistant windows. Durable, energy-efficient, and stylish, perfect for safeguarding your home. Shop now and keep your family safe.
        <Link to="/shop-now" className="shop-now">Shop Now</Link>
      </div>
    </div>
  );
}

export default Carousel;