import React from 'react'
import '../App.css';

const Workflowdesign = () => {
  return (
    <>
        <p>
        Workflow Design ↓ 
        </p>
       
        <div className="button-container">
          <a href="/" className="small-link-button">Home</a>
          <a href="/windows" className="small-link-button">Windows</a>
          <a href="/doors" className="small-link-button">Doors</a>
          <a href="/request-a-quote" className="small-link-button">Request a Quote</a>
          <a href="/home-inspection" className="small-link-button">Home Inspection</a>
          <a href="/rain" className="small-link-button">Rain Effect</a>
        </div>

        <p>
          Features - Product Interest Cart, AI Chat, Schedule Appointment - Not added yet ↓  ↓  ↓ 
        </p>

        <div className="button-container">
          <a href="/" className="small-link-button">Focussed Content</a>
          <a href="/" className="small-link-button">Call to Action</a>
          <a href="/" className="small-link-button">Interactive Elements</a>
          <a href="/" className="small-link-button">Appointment Scheduler</a>
          <a href="/" className="small-link-button">Interest Cart</a>
          <a href="/" className="small-link-button">AI Chat Bot</a>
          <a href="/" className="small-link-button">Rich Media</a>
          <a href="/" className="small-link-button">Portfolio</a>
          <a href="/" className="small-link-button">Tracking</a>
          
        </div>
    </>
  )
}

export default Workflowdesign