import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      const footer = document.querySelector('.footer');
      const footerTop = footer ? footer.offsetTop : 0;
      

      if (window.scrollY > 0 && window.scrollY < footerTop - window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return isVisible ? (
    <button onClick={scrollToTop} className="scroll-to-top">
      <FontAwesomeIcon icon={faArrowCircleUp} />
    </button>
  ) : null;
};

export default ScrollToTop;