import React from 'react';
import '../../css/Hero4.css';
import RequestQuoteForm from '../Quote/QuoteForm';

const Hero4 = () => {
  return (
    <div id="Quote" className="hero4">
      <div className="column">
        <RequestQuoteForm />
      </div>
      <div className="column">
        <h1>REQUEST A QUOTE</h1>
        <div id='request-quote-text'>
          <p>
            Ready to take the next step in protecting what matters most to you? 
          </p>
          <p>
            BNT Impact Windows and doors is your go-to solution for securing your home, your investment, and your loved ones. 
          </p>
          <p>
            Fill out the form now for peace of mind and a safer future. 
          </p>
          <p>
            Let's make it happen together! 
          </p>
          <div className="icon"><div className="arrow-3"></div></div>
        </div>
        
      </div>
    </div>
  );
}

export default Hero4;