import React, { useState } from 'react';
import csvtojson from 'csvtojson';
import { saveAs } from 'file-saver';

function LeadsDashboard() {
    const [data, setData] = useState([]);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function(evt) {
                csvtojson()
                    .fromString(evt.target.result)
                    .then(jsonData => {
                        setData(jsonData);
                    });
            };
            reader.readAsText(file);
        }
    };

    const handleDownload = () => {
        const blob = new Blob([JSON.stringify(data, null, 2)], {type : 'application/json'});
        saveAs(blob, 'data.json');
    };

    return (
        <div>
            <input type="file" accept=".csv" onChange={handleFileUpload} />
            {data.length > 0 && <button onClick={handleDownload}>Download JSON</button>}
            {data.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            {Object.keys(data[0]).map((key, index) => (
                                <th key={index}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                {Object.values(item).map((value, i) => (
                                    <td key={i}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default LeadsDashboard;